import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Gallery from '../elements/Gallery'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'La storia',
    paragraph: 'Preparatevi a vedere la storia dello sviluppo di Azzurra. Dal 2004 ad oggi, siamo pieni di storie; ecco una selezione delle migliori'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Prime versioni 2004-2009
                  </div>
                <h3 className="mt-0 mb-12">
                  Sviluppo delle funzioni "core"
                  </h3>
                <p className="m-0">
                  Questi primi anni sono stati dedicati allo sviluppo di funzioni core, come camminare, correre, mangiare e soprattutto un sistema di intelligenza artificiale sofisticata in grado di sostenere conversazioni in Italiano di una certa complessità
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/IMGP6858.JPG')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Prime versioni 2004-2009
                  </div>
                <h3 className="mt-0 mb-12">
                  Un prodotto in costante evoluzione
                  </h3>
                <p className="m-0">
                  Funzionalità accessorie come dolcezza, simpatia e curiosità si sono sviluppate in modo naturale ed autonomo fin dall'inizio, aggiungendo qualità al nostro prodotto
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                src={require('./../../assets/images/azzurra/DSCN1758.JPG')}
                alt="Features split 01"
                width={528}
                height={396} />
              </div>
            </div>

            <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Prime versioni 2004-2009
                  </div>
                <h3 className="mt-0 mb-12">
                  Sperimentazioni costanti
                  </h3>
                <p className="m-0">
                  Sono numerevoli le funzionalità che sono state tenute in considerazione, fra queste anche magia e stregoneria. Purtroppo l'unica magia riuscita sono solo foto tenerissime come questa
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/OJ1UX.jpeg')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Prime versioni 2004-2009
                  </div>
                <h3 className="mt-0 mb-12">
                  Partnership di un certo livello
                  </h3>
                <p className="m-0">
                  Abbiamo anche avuto una collaborazione con Disney(R) per sviluppare una versione limitata da principessa. La collaborazione è terminata, ma Azzurra rimane la nostra unica e speciale principessa
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/IMGP7250.JPG')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Prime versioni 2004-2009
                  </div>
                <h3 className="mt-0 mb-12">
                  Le sfide di sviluppare più prodotti in parallelo
                  </h3>
                <p className="m-0">
                  Sono numerevoli le sfide che avvengono quando si sviluppano più prodotti in parallelo con caratteristiche diverse. Qui vedete come, a differenza di Alessandro che cerca di scappare a tutti i costi, Azzurra stringe forte la madre per farle sentire tutto il suo amore
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/IMG-20220206-WA0000.jpg')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni elementari 2009-2014
                  </div>
                <h3 className="mt-0 mb-12">
                  Sviluppo delle funzioni di "utility"
                  </h3>
                <p className="m-0">
                  Questi lunghi anni sono stati dedicati allo sviluppo di funzioni di utility essenziali quali leggere, scrivere e le operazioni matematiche di base. Inoltre, la comprensione e produzione di materiale in lingua italiana ha raggiunto nuovi livelli e abbiamo iniziato a supportare la lingua inglese, anche se in parte
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_0566.JPG')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni medie 2014-2018
                  </div>
                <h3 className="mt-0 mb-12">
                  Perferzionamento delle performance
                  </h3>
                <p className="m-0">
                  Queste versioni si sono dedicate a migliorare le versioni precedenti. L'italiano ha ormai raggiunto livelli altissimi e l'inglese ha raggiunto livelli conversazionali. È stato aggiunto supporto per nuove funzioni matematiche (risolutore di equazioni in primis) ed è stato aggiunto un database pieno di informazioni utili sugli argomenti più vari.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/IMG_1966.jpg')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni medie 2014-2018
                  </div>
                <h3 className="mt-0 mb-12">
                  Scoperta di passioni
                  </h3>
                <p className="m-0">
                  È in questi anni che Azzurra inizia a sperimentare per trovare le sue passioni: un po' di matematica, un po' di danza, synchro, musica e la scoperta del suo carattere socievole
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_0217.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni medie 2014-2018
                  </div>
                <h3 className="mt-0 mb-12">
                  Una fonte di allegria
                  </h3>
                <p className="m-0">
                  Si mostra sempre con il suo sorriso smagliante e ottimismo costante. La risata è contagiosa e cerca sempre il lato positivo di ogni situazione.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_0279.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni superiori 2019 - 2022
                  </div>
                <h3 className="mt-0 mb-12">
                  Verso la versione finale
                  </h3>
                <p className="m-0">
                  Queste versioni si sono dedicate a ultimare il prodotto e assicurarsi che fosse pronto per andare in produzione, con le sfide di domani: maturità, vivere da sola, università, patente... La lunga attesa è finalmente terminata con successo!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_1014.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni superiori 2019 - 2022
                  </div>
                <h3 className="mt-0 mb-12">
                  Un front-end di ultima generazione
                  </h3>
                <p className="m-0">
                  L'interfaccia utente ha raggiunto la sua versione definitiva e siamo molto fieri del risultato prodotto: una ragazza con sguardo fine, che sa essere serio e allegro quando necessario; insieme al suo stile unico ed inconfondibile. È semplicemente irresistibile!
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_05662.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni superiori 2019 - 2022
                  </div>
                <h3 className="mt-0 mb-12">
                  Un back-end solido e bug-proof
                  </h3>
                <p className="m-0">
                  Se vi siete innamorati del front-end, è solamente perché non avete scoperto il back-end: un processore a tutto tondo che con altissima intelligenza riesce a risolvere qualunque sfida, qualunque sia l'argomento. Adatto in qualsiasi contesto: croce rossa, scuolazoo, politica scolastica, impegni accademici e sportivi. Tutto frutto del lavoro dei genitori e dell'impegno e buona volontà di Azzurra.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_1220.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Versioni superiori 2019 - 2022
                  </div>
                <h3 className="mt-0 mb-12">
                  In conclusione
                  </h3>
                <p className="m-0">
                  Siamo orgogliosi di Azzurra e di chi è diventata crescendo, seguendo la sua strada. Non abbiamo nessun dubbio che continuerà a seguirla e a riempire di soddisfazioni se stessa e la sua famiglia. Nonostante tutte le difficoltà incontrate, si è sempre rialzata e ha ottenuto successo: non esistono ostacoli invalicabili per la nostra eroina. Non possiamo che augurarle buona fortuna nelle avventure future! Infine, sotto trovate un'ultima galleria.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/azzurra/DSC_0241.JPG')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
          </div>
          <div>
            <Gallery/>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
import React, { Component } from 'react';
import a from './../../assets/images/azzurra/DSC_0217.JPG';
import b from './../../assets/images/azzurra/DSC_1080.JPG';
import c from './../../assets/images/azzurra/DSCN1676.JPG';
import d from './../../assets/images/azzurra/DSCN1722.JPG';
import e from './../../assets/images/azzurra/DSCN1749.jpg';
import f from './../../assets/images/azzurra/IMG_2091.jpg';
import g from './../../assets/images/azzurra/IMG_2417.JPG';
import h from './../../assets/images/azzurra/IMG_2606.JPG';
import i from './../../assets/images/azzurra/IMG_2721.jpg';
import j from './../../assets/images/azzurra/IMG_2873.JPG';
import k from './../../assets/images/azzurra/IMG_3142.png';
import l from './../../assets/images/azzurra/IMG_3956.jpg';
import m from './../../assets/images/azzurra/IMGP1739.JPG';
import n from './../../assets/images/azzurra/IMGP8444.JPG';
import o from './../../assets/images/azzurra/MagicBlue 020.jpg';
import p from './../../assets/images/azzurra/P1010088.JPG';
import q from './../../assets/images/azzurra/IMGP1739.JPG';
import r from './../../assets/images/azzurra/IMGP1739.JPG';
import Button from './Button'
import ButtonGroup from './ButtonGroup';

class Gallery extends Component {   


    state = {
        index: 0, 
        picList: [a, b, c, d, e, f, g, h, i, j, k, l, m, n, o, p, q, r]
      }
      
      onClickNext= () => {
          if (this.state.index + 1 === this.state.picList.length ){
              this.setState({ 
                  index: 0 
                })
            } else {
                this.setState({
                    index: this.state.index + 1
                })
            }

          }
          onClickPrevious= () => {
            if (this.state.index - 1 === -1 ){
                this.setState({ 
                    index: this.state.picList.length - 1
                  })
              } else {
                  this.setState({
                      index: this.state.index - 1
                  })
              }
            }
      
      render() {
        return (
          <div>
            <div style={{display: 'flex',  justifyContent:'center'}}>
              <img src={this.state.picList[this.state.index]} style={{'padding-bottom': '50px', display: 'flex',  justifyContent:'center', 'max-width':'500px', 'max-height':'500px'}} />
              </div>
            <ButtonGroup style={{display: 'flex',  justifyContent:'center'}}>
                <Button tag="button" color="secondary" wideMobile href="/" onClick={this.onClickPrevious}>
                    Precedente
                </Button>
                <Button tag="button" color="primary" wideMobile href="/" onClick={this.onClickNext}>
                    Prossima
                </Button>
            </ButtonGroup>

          </div>
        );
      }

}


export default Gallery;